import { $themeConfig } from "@themeConfig";
////////////////////////////////////////////
const moduleName = "user";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchItems(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        myApi
          .get(moduleName,
            {
              params: queryParams,
            }
          )
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      });
    },
    fetchModuleUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        myApi
          .get(moduleName,
            {
              params: queryParams,
            }
          )
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      });
    },
    fetchModule(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            $themeConfig.app.api_endpoint +
              `get_` +
              moduleName +
              `_infos/${$themeConfig.app.api_endpoint_access_token}/${id}`
          )
          .then((response) => {
            // boolean conversions
            const data_to_update = response.data;
            resolve(data_to_update);
          })
          .catch((error) => reject(error));
      });
    },
    fetchModuleRequirement(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            $themeConfig.app.api_endpoint +
              `get_` +
              moduleName +
              `_requirement/${$themeConfig.app.api_endpoint_access_token}/${id}`
          )
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      });
    },
    addModule(ctx, moduleData) {
      return new Promise((resolve, reject) => {
        axios
          .post($themeConfig.app.api_endpoint + "add_" + moduleName, {
            moduleName: moduleData,
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteModule(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post($themeConfig.app.api_endpoint + "delete_" + moduleName, {
            id: id,
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
